import dotProp from 'dot-prop-immutable';

import {
    REDUCE_CLIENT_DASHBOARD,
    REDUCE_CLIENT_AGENT,
    REDUCE_CLIENT_AGENT_ELEMENTS,
    REDUCE_CLIENT_ENTITY,
    UPDATE_MORTGAGE_INFO,
    REDUCE_HOME_VALUATION_ADDRESS, REDUCE_HOME_VALUATION_ADDRESS2
} from '../../constants/actions/client';

const initialState = {
    entity: null,
    agent: null,
    dashboard: null,
    address: null,
    address2: null
};

function clientReducer(state = initialState, action) {
    switch (action.type) {
        case REDUCE_CLIENT_DASHBOARD:
            return dotProp.set(state, 'dashboard', action.payload);
        case REDUCE_CLIENT_AGENT:
            return dotProp.set(state, 'agent', action.payload);
        case REDUCE_CLIENT_AGENT_ELEMENTS:
            return dotProp.set(state, 'agent', { ...state.agent, [action.payload.code]: action.payload.value });
        case REDUCE_CLIENT_ENTITY:
            return dotProp.set(state, 'entity', action.payload);
        case UPDATE_MORTGAGE_INFO:
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    sections: state.dashboard.sections.map(section => {
                        if (section.type === 'mortgageInfo') {
                            return {
                                ...section,
                                content: {
                                    ...section.content,
                                    mortgageBalance: action.payload.mortgageBalance,
                                    purchasePrice: action.payload.purchasePrice,
                                    interestRate: action.payload.interestRate,
                                    amortizationTerm: action.payload.amortizationTerm,
                                    formattedInterestRate: action.payload.formattedInterestRate,
                                    formattedMortgageBalance: action.payload.formattedMortgageBalance,
                                    formattedPurchasePrice: action.payload.formattedPurchasePrice
                                }
                            };
                        }
                        if (section.type === 'valueDisplay') {
                            return {
                                ...section,
                                content: {
                                    ...section.content,
                                    formattedMortgageBalance: action.payload.formattedMortgageBalance,
                                    mortgageBalance: action.payload.mortgageBalance,
                                    equityAvailable: action.payload.equityAvailable
                                }
                            };
                        }
                        return section;
                    })
                }
            };
        case REDUCE_HOME_VALUATION_ADDRESS:
            if (action.payload === null) {
                return dotProp.set(state, 'address', null);
            }
            const { clientReference, addressesMatch, formattedAddresses } = action.payload;
            const newAddress = { clientReference, addressesMatch };
            if (formattedAddresses) {
                newAddress.formattedAddresses = formattedAddresses;
            }
            return dotProp.set(state, 'address', newAddress);

        case REDUCE_HOME_VALUATION_ADDRESS2:
            if (action.payload === null) {
                return dotProp.set(state, 'address2', null);
            }
            const { clientReference: clientReference2, addressesMatch: addressesMatch2, formattedAddresses: formattedAddresses2 } = action.payload;
            const newAddress2 = { clientReference: clientReference2, addressesMatch: addressesMatch2 };
            if (formattedAddresses2) {
                newAddress2.formattedAddresses = formattedAddresses2;
            }
            return dotProp.set(state, 'address2', newAddress2);
        default:
            return state;
    }
}

export default clientReducer;