export const GET_CLIENT_DASHBOARD = 'GET_CLIENT_DASHBOARD';
export const GET_CLIENT_AUTH_DASHBOARD = 'GET_CLIENT_AUTH_DASHBOARD';
export const GET_AUTH_DASHBOARD = 'GET_AUTH_DASHBOARD';
export const GET_AUTH_DASHBOARD2 = 'GET_AUTH_DASHBOARD2';
export const REDUCE_CLIENT_DASHBOARD = 'REDUCE_CLIENT_DASHBOARD';
export const REDUCE_CLIENT_AGENT = 'REDUCE_CLIENT_GENT';
export const REDUCE_CLIENT_AGENT_ELEMENTS = 'REDUCE_CLIENT_AGENT_ELEMENTS';
export const REDUCE_CLIENT_ENTITY = 'REDUCE_CLIENT_ENTITY';
export const SUBMIT_NEW_LEAD = 'SUBMIT_NEW_LEAD';
export const UPDATE_CLIENT_DASHBOARD = 'UPDATE_CLIENT_DASHBOARD';
export const UPDATE_MORTGAGE_INFO = 'UPDATE_MORTGAGE_INFO';
export const GET_HOME_VALUATION_ADDRESS = 'GET_HOME_VALUATION_ADDRESS';
export const GET_HOME_VALUATION_ADDRESS2 = 'GET_HOME_VALUATION_ADDRESS2';
export const UPDATE_HOME_VALUATION_ADDRESS = 'UPDATE_HOME_VALUATION_ADDRESS';
export const UPDATE_HOME_VALUATION_ADDRESS2 = 'UPDATE_HOME_VALUATION_ADDRESS2';
export const REDUCE_HOME_VALUATION_ADDRESS = 'REDUCE_HOME_VALUATION_ADDRESS';
export const REDUCE_HOME_VALUATION_ADDRESS2 = 'REDUCE_HOME_VALUATION_ADDRESS2';
